import { ReturnPageModel } from 'Kex/KexRouter/FetchPage';
import MagazineCategoryPageModel from 'Models/Pages/MagazineCategoryPage/MagazineCategoryPageModel.interface';
import ArticleProductPageModel from 'Models/Pages/ProductPage/ArticleProductPageModel.interface';
import SubscriptionOfferProductPageModel from 'Models/Pages/ProductPage/SubscriptionOfferProductPageModel.interface';
import GiftCardProductPageModel from 'Models/Pages/ProductPage/GiftCardProductPageModel.interface';
import OrderConfirmationPageModel from 'Models/Pages/OrderConfirmationPage/OrderConfirmationPageModel.interface';
import GoogleTagManagerEvent from 'Models/GoogleTagManager/GoogleTagManagerEvent.interface';
import { canUseDOM } from 'Shared/DOM/WindowHelper';

function gtmHandlePageEvent(page: ReturnPageModel) {
  switch (page.pageType) {
    case 'MagazinePage':
      gtmPushEvent((page as MagazineCategoryPageModel).viewItemEvent);
      break;
    case 'SubscriptionOfferProductPage':
      gtmPushEvent((page as SubscriptionOfferProductPageModel).viewItemEvent);
      break;
    case 'ArticleProductPage':
      gtmPushEvent((page as ArticleProductPageModel).viewItemEvent);
      break;
    case 'GiftCardProductPage':
      gtmPushEvent((page as GiftCardProductPageModel).viewItemEvent);
      break;
    case 'OrderConfirmationPage':
      gtmPushEvent((page as OrderConfirmationPageModel).purchaseEvent);
      break;
    default:
      break;
  }
}

function gtmPushEvent(event: GoogleTagManagerEvent | null | undefined) {
  if (canUseDOM() && event) {
    window.dataLayer = window.dataLayer || [];
    event.ecommerceData.event = event.eventId;
    window.dataLayer.push(event.ecommerceData);
    event = null;
  }
}

export { gtmPushEvent, gtmHandlePageEvent };
